const SENT = 'sent'
const CHECK_CHANGES = 'checkChanges'
const SEND_ANSWERS = 'sendAnswers'
const APPOINTED = 'appointed'
const IN_WORK = 'inWork'

export function getStatus(wasSent, answersDone, length) {
  if (wasSent) return SENT
  if (wasSent === false) return CHECK_CHANGES
  if (answersDone === length) return SEND_ANSWERS
  if (answersDone) return IN_WORK
  return APPOINTED
}

export const questionnaireStatuses = {
  [SENT]: {
    textForExpert: 'Отправлено',
    textForAdmin: 'Отправлена',
    textForWord: 'Эксперт завершил работу над КР.',
    icon: {
      type: 'mdi-checkbox-marked-circle',
      color: 'green',
    },
  },
  [CHECK_CHANGES]: {
    textForExpert: 'Проверьте изменения',
    textForAdmin: 'На доработке',
    textForWord:
      'Эксперт завершил работу над КР, но после этого были изменения в КР. Эксперт еще не подтвердил свое согласие с изменениями.',
    icon: {
      type: 'mdi-alert-circle',
      color: 'amber',
    },
  },
  [SEND_ANSWERS]: {
    textForExpert: 'Отправьте ответы',
    textForAdmin: 'Завершена. Не отправлена',
    textForWord: 'Эксперт ответил на все вопросы, но не отправил анкету.',
    icon: {
      type: 'mdi-alert-circle',
      color: 'amber',
    },
  },
  [APPOINTED]: {
    textForExpert: 'Назначена',
    textForAdmin: 'Назначена',
    textForWord: 'Эксперт еще не начинал работу над анкетой.',
    icon: null,
  },
  [IN_WORK]: {
    textForExpert: 'В работе',
    textForAdmin: 'В работе',
    textForWord: 'Анкета в работе у эксперта и завершена не полностью.',
    icon: null,
  },
}
