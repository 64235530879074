var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.ready)?_c('v-switch',{attrs:{"label":"Скрыть завершенные"},model:{value:(_vm.hideFinished),callback:function ($$v) {_vm.hideFinished=$$v},expression:"hideFinished"}}):_vm._e(),_c('v-data-table',{attrs:{"hide-default-footer":"","headers":_vm.headers,"items":_vm.preparedQuestionnaires,"disable-pagination":"","item-key":"id","loading":!_vm.ready,"loading-text":"Загрузка данных...","no-data-text":"Ничего не нашлось :-("},on:{"click:row":_vm.onRowClick},scopedSlots:_vm._u([{key:"item.statusIcon",fn:function(ref){
var item = ref.item;
return [(item.statusIcon)?_c('v-icon',{attrs:{"color":item.statusIcon.color}},[_vm._v(_vm._s(item.statusIcon.type))]):_vm._e()]}},{key:"item.deadlineIcon",fn:function(ref){
var item = ref.item;
return [(item.deadlineIcon && !item.was_sent)?_c('v-icon',{attrs:{"color":item.deadlineColor}},[_vm._v(_vm._s(item.deadlineIcon))]):_vm._e()]}},{key:"item.deadline",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.displayDeadline))]),(item.readableDayDifference && !item.was_sent)?_c('div',[_vm._v(" "+_vm._s(item.readableDayDifference)+" ")]):_vm._e()]}},{key:"item.percentFinished",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.displayPercentFinished)+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }