<template>
  <div>
    <v-switch
      v-if="ready"
      v-model="hideFinished"
      label="Скрыть завершенные"
    ></v-switch>
    <v-data-table
      hide-default-footer
      :headers="headers"
      :items="preparedQuestionnaires"
      disable-pagination
      item-key="id"
      @click:row="onRowClick"
      :loading="!ready"
      loading-text="Загрузка данных..."
      no-data-text="Ничего не нашлось :-("
    >
      <!-- Отображение иконки статуса -->
      <template v-slot:[`item.statusIcon`]="{ item }">
        <v-icon v-if="item.statusIcon" :color="item.statusIcon.color">{{
          item.statusIcon.type
        }}</v-icon>
      </template>

      <!-- Отображение иконки крайнего срока -->
      <template v-slot:[`item.deadlineIcon`]="{ item }">
        <v-icon
          v-if="item.deadlineIcon && !item.was_sent"
          :color="item.deadlineColor"
          >{{ item.deadlineIcon }}</v-icon
        >
      </template>

      <!-- Отображение текста крайнего срока -->
      <template v-slot:[`item.deadline`]="{ item }">
        <div>{{ item.displayDeadline }}</div>
        <div v-if="item.readableDayDifference && !item.was_sent">
          {{ item.readableDayDifference }}
        </div>
      </template>

      <!-- Читаемое отображение процентов. При этом сортировка работает по числовому значению -->
      <template v-slot:[`item.percentFinished`]="{ item }">
        {{ item.displayPercentFinished }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  isQuestionDone,
  formatPercent,
  typograpf,
  displayDate,
  getDisplayDayDifference,
} from '@/components/helpers'
import {
  questionnaireStatuses,
  getStatus,
} from '@/store/const/questionnaireStatuses'

export default {
  data() {
    return {
      ready: false,
      hideFinished: false,
      headers: [
        {
          text: 'Название',
          value: 'name',
        },
        {
          text: 'Ответов',
          value: 'percentFinished',
          width: 100,
        },
        {
          text: '',
          value: 'deadlineIcon',
          width: 16,
          cellClass: 'pa-0 text-end',
        },
        {
          text: 'Крайний срок',
          value: 'deadline',
          width: 140,
        },
        {
          text: '',
          value: 'statusIcon',
          width: 16,
          cellClass: 'pa-0 text-end',
        },
        {
          text: 'Статус',
          value: 'readableStatus',
          width: 200,
        },
      ],
    }
  },
  async mounted() {
    try {
      await this.loadClinRecs()
      await this.loadQuestionnaires()
      this.ready = true
    } catch (e) {
      this.$toast.error('Ошибка загрузки данных для экспертизы \n' + e)
    }
  },
  computed: {
    ...mapGetters(['getQuestionnaires', 'getClinRecs', 'getLoggedUserId']),
    preparedQuestionnaires() {
      if (!this.ready) return []
      if (this.getClinRecs.length === 0) return []

      // Когда бэк будет отдавать только анкеты пользователя то эта проверка станет лишняя
      const preparedQuestionnaires = this.getQuestionnaires.filter(
        q => q.user_id === this.getLoggedUserId
      )

      preparedQuestionnaires.forEach(q => {
        this.addNameTo(q)
        this.addPercentTo(q)
        this.addStatusTo(q)
        this.addDeadlineTo(q)
      })
      return this.hideFinished
        ? preparedQuestionnaires.filter(cr => !cr.was_sent)
        : preparedQuestionnaires
    },
  },
  methods: {
    ...mapActions(['loadQuestionnaires', 'loadClinRecs']),
    onRowClick(row) {
      this.$router.push(`/question/${row.cr_id}`)
    },
    calculateAmountDone(questionnaire) {
      let amountDone = 0
      questionnaire.data.forEach(q => {
        amountDone += Number(isQuestionDone(q))
      })
      return amountDone
    },
    addNameTo(item) {
      let cr = this.getClinRecs.find(cr => cr.id == item.cr_id)
      item.name = cr?.name ? typograpf(cr.name) : ''
    },
    addPercentTo(item) {
      item.percentFinished = this.calculateAmountDone(item) / item.data.length
      item.displayPercentFinished = formatPercent(item.percentFinished)
    },
    addStatusTo(item) {
      item.status = getStatus(
        item.was_sent,
        this.calculateAmountDone(item),
        item.data.length
      )
      item.readableStatus = questionnaireStatuses[item.status].textForExpert
      item.statusIcon = questionnaireStatuses[item.status].icon
    },
    addDeadlineTo(item) {
      item.deadline = this.getClinRecs.find(
        cr => cr.id == item.cr_id
      ).time_deadline
      item.displayDeadline = displayDate(item.deadline)
      const dayDiff = getDisplayDayDifference(new Date(), item.deadline)
      item.readableDayDifference = dayDiff.shortText
      item.deadlineIcon = dayDiff.icon
      item.deadlineColor = dayDiff.color
    },
  },
}
</script>

<style scoped>
.v-data-table {
  cursor: pointer;
}
</style>
