<template>
  <Page>
    <h1 class="text-h2 mb-4">Список КР для оценки</h1>
    <QuestionList />
  </Page>
</template>

<script>
import Page from '@/components/layout/Page.vue'
import QuestionList from '@/components/expert/QuestionsList.vue'
import { mapActions } from 'vuex'

export default {
  name: 'Home',
  components: {
    QuestionList,
    Page,
  },
  methods: {
    ...mapActions(['loadLoggedUser']),
  },
  created() {
    this.loadLoggedUser()
  },
}
</script>
